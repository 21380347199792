import { put, select } from 'redux-saga/effects';
import {
  clearSession, initApplication, logoutSuccess, setModalError,
} from 'dmpconnectjsapp-base/actions';
import { apiSections } from 'dmpconnectjsapp-base/constants';
import { push } from 'connected-react-router';
import { getCurrentPathname as getCurrentPathnameAccessor } from '../helpers';
import { dmpconnectApplicationActionConstants } from '../constants';
import { setExpiredSession } from '../actions';

let savedPinCode = null;
export function savePinCode(pinCode) {
  savedPinCode = pinCode;
}
export const getSavedPinCode = () => savedPinCode;


export const closedSectionErrorHandler = function* ({ context }) {
  const pathname = yield select(getCurrentPathnameAccessor);
  yield put(clearSession());

  if (pathname === '/') {
    if (context.section !== apiSections.SESSION_STATE_SECTION) {
      window.location.reload();
    }
  } else {
    yield put(setExpiredSession(true));
    yield put(logoutSuccess());
    yield put(clearSession());
    // yield put(resetApplication());
    yield put(initApplication());
    yield put(push('/'));
  }
};

export const insiOperationFailedHandler = function* ({ error, command, modalError }) {
  const { s_apiErrorExtendedInformations } = error;

  if (!s_apiErrorExtendedInformations.includes('KO INS non vérifié')) {
    yield put(setModalError(modalError));
  }
};

export const setLicenseStatus = (status, error) => ({
  type: dmpconnectApplicationActionConstants.DMPC_SET_LICENSE_STATUS,
  status,
  error,
});

export const handleLicenseError = function* ({ error, command }) {
  yield put(setLicenseStatus(false, error));
  if (command.s_pinCode !== undefined) {
    savePinCode(command.s_pinCode);
  }
};
