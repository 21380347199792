import {
  getConfigurationValue, getEsUser, getInsiConfig, isUserLoggedIn,
} from 'dmpconnectjsapp-base/helpers/accessors';
import { esLoginTypes } from 'dmpconnectjsapp-base/reducers/dmpconnectESConfiguration';
import { setModalError } from 'dmpconnectjsapp-base/actions';
import { createError, errorTypes, jwtErrors } from 'dmpconnectjsapp-base/errors';
import getCommandAction from 'dmpconnectjsapp-base/actions/config/generateCommands';
import commands from 'dmpconnectjsapp-base/actions/config/commands';
import { formatGetInsFromIdentityInformationParams } from 'dmpconnectjsapp-base/actions/config/commandParamsFormatters';
import { API_TYPES } from 'dmpconnectjsapp-base/constants';
import { getSessionId } from '../helpers';
import { dmpconnectActionConstants } from '../constants';
import { apiSections } from '../constants/apiConstants';
import env from '../../envVariables';
import { formatValues } from '../helpers/insi/checkIdentity';
import {getIframeParentJWTError, verifyAndDecodeJWT} from '../helpers/jwtUtils';
import { createErrorDetails, createModalError, getErrorDescription } from '../errors';
import { getDmpconnectConnectorConfig } from '../sagas/utilsSaga';


export const getAction = (
  commandName,
  section,
  values,
  {
    synchronous = false,
    contextParams = {},
    contextExtra = {},
    subSection = '',
    forceEs = false,
    silentError,
    sessionId = null,
  } = {},
) => ((dispatch, getState) => {
  // check the token if needed
  const { dmpconnectESConfiguration, dmpconnectConnectorConfig } = getState();
  const apiType = getConfigurationValue('apiType', dmpconnectConnectorConfig);
  if (apiType === API_TYPES.REST) {
    const loginType = getConfigurationValue('loginType', dmpconnectESConfiguration);
    const loggedIn = isUserLoggedIn(getState());
    const esUser = getEsUser(getState());

    if (loginType === esLoginTypes.TOKEN && loggedIn) {
      if (esUser.token) {
        const decodedToken = verifyAndDecodeJWT(esUser.token);
        if (!decodedToken.valid) {
          const modalError = createModalError(
            decodedToken.error,
            [createErrorDetails('Erreur détaillée', decodedToken.error)],
          );
          window.parent.postMessage(getIframeParentJWTError(decodedToken.error), '*');
          return dispatch(setModalError(modalError));
        }
      } else {
        const error = createError(errorTypes.JWTErrors, jwtErrors.EMPTY_TOKEN);
        const modalError = createModalError(
          error,
          [createErrorDetails('Erreur détaillée', error)],
        );
        window.parent.postMessage({
          type: 'empty_token',
          desc: getErrorDescription(error).message,
        }, '*');
        return dispatch(setModalError(modalError));
      }
    }
  }
  const action = getCommandAction(
    commandName,
    section,
    values,
    {
      synchronous,
      contextParams,
      contextExtra,
      subSection,
      forceEs,
      silentError,
      sessionId,
    },
  );
  return dispatch(action);
});

export const readCpxProcess = (pinCode, readerNumber, doLogin = true, forceLoginStepTwo = false) => ({
  type: dmpconnectActionConstants.DMPC_READ_CPX_PROCESS,
  pinCode,
  readerNumber,
  doLogin,
  forceLoginStepTwo,
});

export const cancelCommand = (section, subSection) => ({
  type: dmpconnectActionConstants.DMPC_CANCEL_COMMAND,
  context: {
    section,
    subSection,
  },
});

export const selectINS = (ins) => {
  let tmpIns = ins;
  if (ins === undefined || ins === '' || ins === 0) tmpIns = '-1';

  return {
    type: dmpconnectActionConstants.DMPC_SELECT_INS,
    ins: tmpIns,
  };
};


export const dmpSynchronousCommandFailure = (data, context, command, silentError = false) => ({
  type: dmpconnectActionConstants.DMPC_SYNCHRONOUS_COMMAND_FAILURE,
  data,
  command,
  context,
  silentError,
});

export const dmpSynchronousCommandSuccess = (data, context, command) => ({
  type: dmpconnectActionConstants.DMPC_SYNCHRONOUS_COMMAND_SUCCESS,
  data,
  command,
  context,
});

export const dmpCommandFailure = (data, context, command, silentError = false) => ({
  type: dmpconnectActionConstants.DMPC_COMMAND_FAILURE,
  data,
  command,
  context,
  silentError,
});

export const dmpCommandTooLong = (context, command) => ({
  type: dmpconnectActionConstants.DMPC_COMMAND_TOO_LONG,
  command,
  context,
});

export const dmpCommandStopped = context => ({
  type: dmpconnectActionConstants.DMPC_COMMAND_STOP,
  context,
});

export const dmpCommandStartContextualizedType = section => `DMPC_COMMAND_${section.toUpperCase()}_START`;
export const dmpCommandTooLongContextualizedType = section => `DMPC_COMMAND_${section.toUpperCase()}_TOO_LONG`;

export const dmpCommandStartContextualized = (context, command) => ({
  type: dmpCommandStartContextualizedType(context.section),
  command,
  context,
});

export const dmpCommandTooLongContextualized = (context, command) => ({
  type: dmpCommandTooLongContextualizedType(context.section),
  command,
  context,
});

export const dmpCommandFailureContextualizedType = section => `DMPC_COMMAND_${section.toUpperCase()}_FAILURE`;

export const dmpCommandSuccessContextualizedType = section => `DMPC_COMMAND_${section.toUpperCase()}_SUCCESS`;

export const dmpCommandFailureContextualized = (data, context, command) => ({
  type: dmpCommandFailureContextualizedType(context.section),
  data,
  command,
  context,
});

export const dmpCommandSuccess = (data, context, command) => ({
  type: dmpconnectActionConstants.DMPC_COMMAND_SUCCESS,
  data,
  command,
  context,
});

export const dmpCommandSuccessContextualized = (data, context, command) => ({
  type: dmpCommandSuccessContextualizedType(context.section),
  data,
  command,
  context,
});

export const isDcParamsRegistered = dcParams => ({
  type: dmpconnectActionConstants.DMPC_SEND_HTTP_COMMAND,
  endpoint: '/remotecommand/isDcParameterRegistered',
  command: {
    s_dcparameters64: dcParams || undefined,
  },
  context: {
    section: apiSections.IS_REGISTERED_DC_PARAMS,
  },
  silentError: true,
});

export const registerDcParams = dcParams => ({
  type: dmpconnectActionConstants.DMPC_SEND_HTTP_COMMAND,
  endpoint: '/remotecommand/registerDcParameter',
  command: {
    s_dcparameters64: dcParams || undefined,
  },
  context: {
    section: apiSections.REGISTER_DC_PARAMS,
  },
  silentError: true,
});

export const unRegisterDcParams = dcParams => ({
  type: dmpconnectActionConstants.DMPC_SEND_HTTP_COMMAND,
  endpoint: '/remotecommand/unregisterDcParameter',
  command: {
    s_dcparameters64: dcParams || undefined,
  },
  context: {
    section: apiSections.UNREGISTER_DC_PARAMS,
  },
  silentError: true,
});

export const initSession = (dcParams, tseWhoami) => ({
  type: dmpconnectActionConstants.DMPC_SEND_SYNCHRONOUS_COMMAND,
  command: {
    s_commandName: 'hl_openSession',
    i_timeoutInSeconds: Number(env.REACT_APP_SESSION_TIMEOUT), // 1h
    MssParameters:
      {
        s_numHomologation: 'icanopee_dmpcjs_msssample',
        s_producerAddress: '1 rue de la gaieté\n01234 Bon espoir\nTel : 01 02 03 04 05',
        s_softwareDetails: 'DmpConnect-JS (MSS) de la société Icanopée (contact contact@icanopee.fr)',
      },
    s_dcparameters64: dcParams || undefined,
    s_tseCredentials: tseWhoami || undefined,
    s_applicationId: 'insiConsult',
  },
  context: {
    section: apiSections.SESSION_SECTION,
  },
});


export const closeSession = (synchronous = true, sessionId, silentError = false) => ((dispatch, getState) => (
  dispatch(
    {
      type:
        synchronous
          ? dmpconnectActionConstants.DMPC_SEND_SYNCHRONOUS_COMMAND
          : dmpconnectActionConstants.DMPC_SEND_COMMAND,
      command: {
        s_commandName: 'hl_closeSession',
        s_sessionId: sessionId || getSessionId(getState()),
      },
      context: {
        section: apiSections.SESSION_SECTION,
      },
      silentError,
    },
  )
));

export const clearSection = section => ({
  type: dmpconnectActionConstants.DMPC_CLEAR_SECTION,
  section,
});

export const clearSubSection = (section, subSection) => ({
  type: dmpconnectActionConstants.DMPC_CLEAR_SUB_SECTION,
  section,
  subSection,
});

export const clearData = () => ({
  type: dmpconnectActionConstants.DMPC_CLEAR_DATA,
});

export const getSessionState = (synchronous = false) => ((dispatch, getState) => dispatch(
  {
    type:
      synchronous
        ? dmpconnectActionConstants.DMPC_SEND_SYNCHRONOUS_COMMAND
        : dmpconnectActionConstants.DMPC_SEND_COMMAND,
    command: {
      s_commandName: 'hl_getSessionState',
      i_timeoutInSeconds: 3600,
      s_sessionId: getSessionId(getState()),
    },
    context: {
      section: apiSections.SESSION_STATE_SECTION,
    },
  },
));

export const clearSession = () => ({
  type: dmpconnectActionConstants.DMPC_CLEAR_SESSION,
});

export const getCpxCard = (synchronous = true, readerNumber = 0) => (dispatch, getState) => {
  dispatch(
    {
      type:
        synchronous
          ? dmpconnectActionConstants.DMPC_SEND_SYNCHRONOUS_COMMAND
          : dmpconnectActionConstants.DMPC_SEND_COMMAND,
      command: {
        s_commandName: 'hl_getCpxCard',
        i_usePcsc: 1,
        i_readerNumber: readerNumber,
        s_readerName: '', // Currently ignored.
        s_sessionId: getSessionId(getState()),
      },
      context: {
        section: apiSections.CPX_SECTION,
      },
    },
  );
};

export const getCpxStatus = (synchronous = true) => (dispatch, getState) => {
  dispatch(
    {
      type:
        synchronous
          ? dmpconnectActionConstants.DMPC_SEND_SYNCHRONOUS_COMMAND
          : dmpconnectActionConstants.DMPC_SEND_COMMAND,
      command: {
        s_commandName: 'hl_getCpxStatus',
        s_sessionId: getSessionId(getState()),
      },
      context: {
        section: apiSections.CPX_STATUS_SECTION,
      },
    },
  );
};

export const readCpxCard = pinCode => (
  (dispatch, getState) => dispatch(
    {
      type: dmpconnectActionConstants.DMPC_SEND_SYNCHRONOUS_COMMAND,
      command: {
        s_commandName: 'hl_readCpxCard',
        s_pinCode: pinCode,
        s_sessionId: getSessionId(getState()),
      },
      context: {
        section: apiSections.CPX_CARD_SECTION,
      },
    },
  )
);

export const requestPcscReaders = synchronous => ({
  type: dmpconnectActionConstants.DMPC_REQUES_PCSC_READERS,
  synchronous,
});
export const getRestPcscReaders = () => ({
  type: dmpconnectActionConstants.DMPC_GET_REST_PCSC_READERS,
});

export const getVitaleCard = readerNumber => ((dispatch, getState) => dispatch(
  {
    type: dmpconnectActionConstants.DMPC_SEND_COMMAND,
    command: {
      s_commandName: 'hl_getVitaleCard',
      i_usePcsc: 1,
      i_readerNumber: readerNumber,
      s_readerName: '',
      s_sessionId: getSessionId(getState()),
    },
    context: {
      section: apiSections.VITALE_SECTION,
    },
  },
));

export const readVitaleCard = (check = false) => ((dispatch, getState) => dispatch(
  {
    type: dmpconnectActionConstants.DMPC_SEND_COMMAND,
    command: {
      s_commandName: 'hl_readVitaleCard',
      s_sessionId: getSessionId(getState()),
    },
    context: {
      section: check ? apiSections.VITALE_CARD_CHECK_SECTION : apiSections.VITALE_CARD_SECTION,
    },
  },
));

export const getCpxCardType = cpxHandle => ((dispatch, getState) => dispatch(
  {
    type: dmpconnectActionConstants.DMPC_SEND_COMMAND,
    command: {
      s_commandName: 'getObjectParameterInt',
      s_sessionId: getSessionId(getState()),
      i_parameter: 69,
      i_object: cpxHandle,
    },
    context: {
      section: apiSections.CPX_CARD_TYPE_SECTION,
    },
  },
));


export const getInsFromVitaleCardProcess = vitaleIndex => ({
  type: dmpconnectActionConstants.DMPC_GET_INS_FROM_VITALE_CARD_PROCESS,
  vitaleIndex,
});
export const insi_getInsFromVitaleCard = (vitaleIndex, vitaleData) => ((dispatch, getState) => {
  const insiConfig = getInsiConfig(getState());

  const LpsInfos = {
    s_idam: insiConfig.idam,
    s_numAM: insiConfig.numAm,
    s_version: insiConfig.lpsVersion,
    s_instance: '550e8400-e29b-41d4-a716-446655440000',
    s_name: insiConfig.lpsName,
    s_billingNumber: insiConfig.billingNumber,
  };

  return dispatch(getAction(
    commands.getInsFromVitaleCard,
    apiSections.INSI_GET_INS,
    {
      LpsInfos,
      i_vitalePatientIndex: Number(vitaleIndex),
      s_commandName: 'hl_getInsFromVitaleCard',
      s_sessionId: getSessionId(getState()),
    },
    {
      contextParams: {
        vitaleData,
      },
    },
  ));
});

export const insi_getInsFromRawVitaleCard = rawData => ((dispatch, getState) => {
  const insiConfig = getInsiConfig(getState());
  const dmpConfiguration = getDmpconnectConnectorConfig(getState());
  const serverName = getConfigurationValue('tlsiServerName', dmpConfiguration);
  const LpsInfos = {
    s_idam: insiConfig.idam,
    s_numAM: insiConfig.numAm,
    s_version: insiConfig.lpsVersion,
    s_instance: '550e8400-e29b-41d4-a716-446655440000',
    s_name: insiConfig.lpsName,
    s_billingNumber: insiConfig.billingNumber,
  };

  return dispatch(getAction(
    commands.getInsFromRawVitaleData,
    apiSections.INSI_GET_INS,
    {
      LpsInfos,
      s_insiUrl: serverName,
      ...rawData,
    },
  ));
});

export const insi_getInsFromIdentityInformation = values => ((dispatch, getState) => {
  const insiConfig = getInsiConfig(getState());
  const dmpConfiguration = getDmpconnectConnectorConfig(getState());
  const serverName = getConfigurationValue('tlsiServerName', dmpConfiguration);

  const onlyDigitRegex = new RegExp('^[0-9]*$');

  // ne pas envoyer les lieux de naissance contenant des lettres en prod
  const birthPlace = (onlyDigitRegex.test(values.birthplace) || Number(env.REACT_APP_PRODUCTON_MODE) === 0) && values.birthplace !== '99999'
    ? values.birthplace
    : '';

  return dispatch(getAction(
    commands.getInsFromIdentityInformation,
    apiSections.INSI_GET_INS,
    {
      s_insiUrl: serverName,
      ...formatGetInsFromIdentityInformationParams({
        name: values.name.trim(),
        given: values.given.trim(),
        birthday: values.birthday,
        birthplace: birthPlace,
        sex: Number(values.sex),
        idam: insiConfig.idam,
        numAm: insiConfig.numAm,
        lpsName: insiConfig.lpsName,
        lpsVersion: insiConfig.lpsVersion,
        billingNumber: insiConfig.billingNumber,
      }),
    },
    { contextParams: { search: values } },
  ));
});

export const insi_checkIndentity = values => ((dispatch, getState) => {
  const insiConfig = getInsiConfig(getState());
  const dmpConfiguration = getDmpconnectConnectorConfig(getState());
  const serverName = getConfigurationValue('tlsiServerName', dmpConfiguration);

  const LpsInfos = {
    s_idam: insiConfig.idam,
    s_numAM: insiConfig.numAm,
    s_version: insiConfig.lpsVersion,
    s_instance: '550e8400-e29b-41d4-a716-446655440000',
    s_name: insiConfig.lpsName,
    s_billingNumber: insiConfig.billingNumber,
  };

  return dispatch(getAction(
    commands.checkIdentity,
    apiSections.INSI_CHECK_IDENTITY,
    {
      LpsInfos,
      ...formatValues(values),
      s_insiUrl: serverName,
    },
    {
      contextParams: values,
    },
  ));
});


export const insi_checkIndentityBatch = (batch, batchId, parentBatchId, identities) => ((dispatch, getState) => {
  const insiConfig = getInsiConfig(getState());
  const dmpConfiguration = getDmpconnectConnectorConfig(getState());
  const serverName = getConfigurationValue('tlsiServerName', dmpConfiguration);
  const LpsInfos = {
    s_idam: insiConfig.idam,
    s_numAM: insiConfig.numAm,
    s_version: insiConfig.lpsVersion,
    s_instance: '550e8400-e29b-41d4-a716-446655440000',
    s_name: insiConfig.lpsName,
    s_billingNumber: insiConfig.billingNumber,
  };
  return dispatch(getAction(
    commands.submitInsiBatchValidationSet,
    apiSections.INSI_SUBMIT_IDENTITY_BATCH,
    {
      LpsInfos,
      Patients: batch,
      s_insiUrl: serverName,
    },
    {
      subSection: batchId,
      contextParams: { identities },
      contextExtra: { parentBatchId },
    },
  ));
});

export const insi_getBatchResult = (subSection, batchId, parentBatchId, identities) => ((dispatch, getState) => {
  const insiConfig = getInsiConfig(getState());
  const dmpConfiguration = getDmpconnectConnectorConfig(getState());
  const serverName = getConfigurationValue('tlsiServerName', dmpConfiguration);
  const LpsInfos = {
    s_idam: insiConfig.idam,
    s_numAM: insiConfig.numAm,
    s_version: insiConfig.lpsVersion,
    s_instance: '550e8400-e29b-41d4-a716-446655440000',
    s_name: insiConfig.lpsName,
    s_billingNumber: insiConfig.billingNumber,
  };

  return dispatch(getAction(
    commands.getInsiBatchValidationSetResult,
    apiSections.INSI_RESULT_IDENTITY_BATCH,
    {
      LpsInfos,
      s_batchId: batchId,
      s_insiUrl: serverName,
    },
    {
      subSection,
      contextParams: { identities },
      contextExtra: { parentBatchId },
    },
  ));
});

export const checkUserLicenseRight = () => ((dispatch, getState) => dispatch({
  type: dmpconnectActionConstants.DMPC_SEND_COMMAND,
  command: {
    s_commandName: 'hl_checkUserLicenseRight',
    s_sessionId: getSessionId(getState()),
  },
  context: {
    section: apiSections.CHECK_LICENSE_USER_RIGHT,
  },
}));

export const getVitaleXmlContent = (vitaleHandle, check) => ((dispatch, getState) => dispatch(
  {
    type: dmpconnectActionConstants.DMPC_SEND_COMMAND,
    command: {
      s_commandName: 'getObjectParameterString',
      s_sessionId: getSessionId(getState()),
      i_parameter: 30,
      i_resultInBase64: 1,
      i_object: vitaleHandle,
    },
    context: {
      section: check ? apiSections.VITALE_XML_CONTENT_CHECK_SECTION : apiSections.VITALE_XML_CONTENT_SECTION,
    },
  },
));

export const errorVitaleCardChanged = error => ({
  type: dmpconnectActionConstants.DMPC_ERROR_VITALE_CARD_CHANGED,
  error,
});

export const getPersistantData = () => ((dispatch, getState) => dispatch(
  getAction(
    commands.getPersistantData,
    apiSections.GET_PERSISTANT_DATA,
  ),
));

export const setPersistantData = data => ((dispatch, getState) => dispatch(
  getAction(
    commands.setPersistantData,
    apiSections.SET_PERSISTANT_DATA,
    {
      s_persistantData64: data,
    },
  ),
));

export const clearPersistantData = () => ((dispatch, getState) => dispatch(
  getAction(
    commands.setPersistantData,
    apiSections.CLEAR_PERSISTANT_DATA,
  ),
));

export const saveBatchInPersistantData = ({
  parentBatchId, batch, clear,
}) => ({
  type: dmpconnectActionConstants.DMPC_SAVE_BATCH_IN_PERSISTANT_DATA,
  parentBatchId,
  batch,
  clear,
});
export const saveImportedIdentitiesInPersistantData = ({
  parentBatchId, identities,
}) => ({
  type: dmpconnectActionConstants.DMPC_SAVE_BATCH_IN_PERSISTANT_DATA,
  parentBatchId,
  identities,
});
export const saveExportedInPersistantData = ({ parentBatchId, exported }) => ({
  type: dmpconnectActionConstants.DMPC_SAVE_BATCH_IN_PERSISTANT_DATA,
  parentBatchId,
  exported,
});

export const checkPendingBatches = (batchId, batches) => ({
  type: dmpconnectActionConstants.DMPC_INSI_CHECK_PENDING_BATCHES,
  batchId,
  batches,
});

export const exportBatchResult = (batchId, result) => ({
  type: dmpconnectActionConstants.DMPC_INSI_EXPORT_RESULT,
  batchId,
  result,
});

export const stopAllGetResults = () => ({
  type: dmpconnectActionConstants.DMPC_INSI_STOP_ALL_GET_RESULTS,
});
