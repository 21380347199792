import React, {lazy, Suspense} from 'react';
import {Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import CenteredCard from './components/Common/Card/CenteredCard';
import CenteredLoading from './components/Common/Loading/CenteredLoading';
// Components

import {routes} from './router';
import Footer from "./components/Common/Version/Footer";
import {ToastContainer} from "react-toastify";
import env, {generateEnvCheckSum} from "./envVariables";
import IframeMessageReceiver from "./components/iframe/IframeMessageReceiver";
import {initApplication} from "dmpconnectjsapp-base/actions";
import CardReaderProvider from "./components/Card/CardReaderProvider";
import {getConfigurationValue} from "dmpconnectjsapp-base/helpers/accessors";
import {resetAllConfigFromEnv, setPersistedAppConfiguration} from "./dmpconnect/actions";

const InitApp = lazy(() => import('./components/Init/InitApp'));
const DMPSearch = lazy(() => import('./scenes/DMPSearch'));
const DMPConfiguration = lazy(() => import('./scenes/DMPConfiguration'));
const DMPDebug = lazy(() => import('./scenes/DMPDebug'));
const ErrorModal = lazy(() => import('./components/Error/ErrorModal'));
const PageNotFound = lazy(() => import('./scenes/PageNotFound'));
const DMPAccess = lazy(() => import('./scenes/DMPAccess'));
const DMPTseConfig = lazy(() => import('./scenes/DMPTseConfig'));
const DMPEsConfig = lazy(() => import('./scenes/DMPEsConfig'));
const EsSilentRenewCallback = lazy(() => import('./components/DMPLogin/esSilentRenewCallback'));
const EsLoginCallback = lazy(() => import('./components/DMPLogin/esLoginCallback'));
const DMPLogout = lazy(() => import('./components/DMPLogin/DMPLogout'));


const App = ({ dispatch, applicationInitialized, envCheckSum, pathname, urlProcessed }) => {

  React.useEffect(() => {
    const currentEnvChecksum = generateEnvCheckSum(env);
    if (envCheckSum !== currentEnvChecksum) {
      dispatch(resetAllConfigFromEnv());
      dispatch(setPersistedAppConfiguration('envCheckSum', currentEnvChecksum));
    }
  }, [env, envCheckSum]);

  React.useEffect(() => {
    if (pathname !== '/logout' && urlProcessed) {
      dispatch(initApplication());
    }
  }, [urlProcessed]);


  return (
    <>
      <Suspense fallback={
        <CenteredCard><CenteredLoading message="Chargement ..."/></CenteredCard>}
      >
        <Switch>
          <Route path={routes.logout.path} component={DMPLogout} />
          {Number(env.REACT_APP_DEBUG) === 1 && (
            <Route exact path={routes.debug.path} component={DMPDebug}/>
          )}
          <Route exact path={routes.tseConfig.path} component={DMPTseConfig}/>
          <Route path="/*">
            {applicationInitialized ? (
              <CardReaderProvider>
                <Switch>
                  <Route exact path="/silent-sso" component={EsSilentRenewCallback}/>
                  <Route exact path="/authcallback" component={EsLoginCallback}/>
                  <Route exact path={routes.esConfig.path} component={DMPEsConfig}/>
                  <Route path="/*">
                    <DMPAccess>
                      <Switch>
                        <Route path={routes.dmpSearch.path} component={DMPSearch}/>
                        <Route exact path={routes.configuration.path} component={DMPConfiguration}/>
                        <Route path="/*" component={PageNotFound}/>
                      </Switch>
                    </DMPAccess>
                  </Route>
                </Switch>
              </CardReaderProvider>
            ) : (
              <InitApp/>
            )}
          </Route>
        </Switch>

        <ErrorModal/>
        <ToastContainer/>
        <IframeMessageReceiver/>
      </Suspense>
      <Footer/>
    </>
  );
}

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
  applicationInitialized: PropTypes.bool,
  urlProcessed: PropTypes.bool,
  envCheckSum: PropTypes.string,
  pathname: PropTypes.string.isRequired,
};

App.defaultProps = {
  urlProcessed: false,
  applicationInitialized: false,
  envCheckSum: null,
};

function mapStateToProps(state) {
  const {
    router: { location: { pathname } },
    dmpconnectInit: {
      applicationInitialized,
    },
    dmpconnectApplication: {
      urlProcessed,
    },
    dmpConnectPersistedAppConfiguration
  } = state;
  return {
    pathname,
    urlProcessed,
    applicationInitialized,
    envCheckSum: getConfigurationValue('envCheckSum', dmpConnectPersistedAppConfiguration),
  };
}

const connectedApp = connect(mapStateToProps,
  null,
  null,
  {
    pure: false,
  })(App);

export default connectedApp;
