import merge from 'lodash.merge';
import { LOCATION_CHANGE } from 'connected-react-router';
import { dmpconnectApplicationActionConstants, PANELS } from '../constants';
import {
  dmpCommandFailureContextualizedType,
  dmpCommandStartContextualizedType,
  dmpCommandSuccessContextualizedType,
} from '../actions';
import { apiSections } from '../constants/apiConstants';

const initialState = {
  modalError: null,
  [apiSections.INTEROPCODES]: {},
  panel: PANELS.BUTTONS,
  newSearchPanel: null,
  vitaleReading: false,
  vitaleChecking: false,
  exportId: undefined,
  urlProcessed: false,
};

export function dmpconnectApplication(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return { ...state, urlProcessed: action.payload.action === 'POP' ? false : state.urlProcessed };
    case dmpconnectApplicationActionConstants.DMPS_SET_URL_PROCESSED:
      return { ...state, urlProcessed: action.processed };
    case dmpconnectApplicationActionConstants.DMPC_RESET_APPLICATION:
      return initialState;
    case dmpconnectApplicationActionConstants.DMPC_SET_MODAL_ERROR: {
      const { modalError, ...newState } = state;
      return merge({}, newState, { modalError: action.modalError });
    }
    case dmpconnectApplicationActionConstants.DMPC_CLEAR_MODAL_ERROR: {
      const { modalError, ...newState } = state;
      return { ...newState, modalError: null };
    }
    case dmpconnectApplicationActionConstants.DMPC_SET_PANEL:
      return merge({}, state, { panel: action.panel, newSearchPanel: action.newSearchPanel });

    case dmpCommandStartContextualizedType(apiSections.VITALE_CARD_SECTION):
      return merge({}, state, { vitaleReading: true });
    case dmpCommandSuccessContextualizedType(apiSections.VITALE_XML_CONTENT_SECTION):
    case dmpCommandFailureContextualizedType(apiSections.VITALE_XML_CONTENT_SECTION):
    case dmpCommandFailureContextualizedType(apiSections.VITALE_CARD_SECTION):
      return merge({}, state, { vitaleReading: false });
    case dmpCommandStartContextualizedType(apiSections.VITALE_CARD_CHECK_SECTION):
      return merge({}, state, { vitaleChecking: true });
    case dmpCommandFailureContextualizedType(apiSections.VITALE_CARD_CHECK_SECTION):
    case dmpCommandSuccessContextualizedType(apiSections.VITALE_XML_CONTENT_CHECK_SECTION):
    case dmpCommandFailureContextualizedType(apiSections.VITALE_XML_CONTENT_CHECK_SECTION):
      return merge({}, state, { vitaleChecking: false });
    case dmpconnectApplicationActionConstants.DMPC_SET_EXPORT_ID:
      return merge({}, state, { exportId: action.exportId });
    case dmpconnectApplicationActionConstants.SET_IS_FIRST_LOGIN:
      return merge({}, state, { isFirstLogin: action.value });

    default:
      return state;
  }
}
