import env from '../envVariables';

export const connectorConfigFromEnv = {
  applicationId: env.REACT_APP_ID,
  production_mode: Number(env.REACT_APP_PRODUCTON_MODE) === 1,
  dmpcjsProtocol: env.REACT_APP_DMPCONNECTJS_PROTOCOL,
  dmpcjsHttpProtocol: env.REACT_APP_DMPCONNECTJS_HTTPPROTOCOL,
  dmpcjsHost: env.REACT_APP_DMPCONNECTJS_HOST,
  dmpcjsPort: env.REACT_APP_DMPCONNECTJS_PORT,
  dmpcjsVersion: env.REACT_APP_DMPCONNECTJS_VERSION,

  apiType: env.REACT_APP_API_TYPE,
  air: Number(env.REACT_APP_AIR) === 1,

  esRESTHost: env.REACT_APP_ES_REST_HOST,
  esRESTPort: env.REACT_APP_ES_REST_PORT,
  esRestUrl: env.REACT_APP_ES_REST_URL,

  serverName: env.REACT_APP_DMP_SERVER_NAME,
  webPsRootUrl: env.REACT_APP_WEB_PS_BASE_URL,

  dcparams: env.REACT_APP_DCPARAMS,

  debugActivateRemoteControl: Number(env.REACT_APP_REMOTE_CONTROL) === 1,
  forceDesactivateRemoteControl: false,
  tlsiServerName: env.REACT_APP_TLSI_SERVER_NAME || env.REACT_APP_DMP_SERVER_NAME,

  consultOnly: Number(env.REACT_APP_CONSULT_ONLY) === 1,
  debugNdp: env.REACT_APP_DP_DEBUG_NDP,
  airOnly: Number(env.REACT_APP_AIR_NO_ES) === 1,
  exportCdaDocument: Number(env.REACT_APP_ES_REST_EXPORT_CDA_DOCUMENT) === 1,
};

export const persistedConnectorConfigFromEnv = {
  onlyGeneratedConnectorJWT: Number(env.REACT_APP_GENERATED_CONNECTOR_JWT) === 1,
  esRestVitale: Number(env.REACT_APP_ES_REST_ENABLE_VITALE_CARD_READING) === 1,
};

export const tseConfigFromEnv = {
  active: Number(env.REACT_APP_TSE) === 1,
};

export const esConfigFromEnv = {
  loginType: env.REACT_APP_ES_LOGIN_TYPE,
  configRequired: Number(env.REACT_APP_ES_CONFIG_NEEDED) === 1,
  hpAuthenticationMode: Number(env.REACT_APP_ES_AUTHENTICATION_MODE) || 8,
  hpAuthenticationContext: Number(env.REACT_APP_ES_AUTHENTICATION_CONTEXT) || 3,
  hpGiven: Number(env.REACT_APP_DEBUG) === 1 ? 'Dr MED-CS RPPS0020810' : undefined,
  hpInternalId: Number(env.REACT_APP_DEBUG) === 1 ? '899700208103' : undefined,
  hpName: Number(env.REACT_APP_DEBUG) === 1 ? 'ANNE' : undefined,
  hpProfession: Number(env.REACT_APP_DEBUG) === 1 ? '10' : undefined,
  hpProfessionOid: Number(env.REACT_APP_DEBUG) === 1 ? '1.2.250.1.71.1.2.7' : undefined,
  hpSpeciality: Number(env.REACT_APP_DEBUG) === 1 ? 'SM26' : undefined,
  es_id: env.REACT_APP_ES_ID_INSI,
  es_finess: env.REACT_APP_ES_FINESS_INSI,
  esLoginActive: Number(env.REACT_APP_ES) === 1,
  esCertificate: env.REACT_APP_ES_CERT_INSI,
  practiceLocationName: env.REACT_APP_ES_LOCATION_NAME,
  activitySector: env.REACT_APP_ES_ACTIVITY_SECTOR,
  practiceSetting: env.REACT_APP_ES_PRACTICE_SETTING,
  healthcareSetting: env.REACT_APP_ES_HEALTHCARE_SETTING,
  loginApiEndpoint: env.REACT_APP_ES_LOGIN_API_ENDPOINT,
  loginApiPSParam: env.REACT_APP_ES_LOGIN_API_ENDPOINT_PS_PARAM,
  loginApiPatientParam: env.REACT_APP_ES_LOGIN_API_ENDPOINT_PATIENT_PARAM,
  cpxLoginActive: Number(env.REACT_APP_ES_CPX_LOGIN_ACTIVE) === 1,
};

export const insiConfigFromEnv = {
  active: 1,
  idam: env.REACT_APP_INSI_ID_AM,
  numAm: env.REACT_APP_INSI_NUM_AM,
  lpsName: env.REACT_APP_INSI_LPS_NAME,
  lpsVersion: env.REACT_APP_INSI_LPS_VERSION,
};

export const displayConfigFromEnv = {
  hideConfig: Number(env.REACT_APP_HIDE_CONFIG) === 1,
  hideBackButton: Number(env.REACT_APP_HIDE_BACK_BUTTON) === 1,
  hideNewSearchButton: Number(env.REACT_APP_HIDE_NEW_SEARCH_BUTTON) === 1,
  hideCopyAllButton: Number(env.REACT_APP_HIDE_COPY_ALL_BUTTON) === 1,
  hideCopyInsButton: Number(env.REACT_APP_HIDE_COPY_INS_BUTTON) === 1,
  hideExportButton: Number(env.REACT_APP_HIDE_EXPORT_BUTTON) === 1,
  exportLabel: env.REACT_APP_EXPORT_TO_LABEL,
  exportFullLabel: env.REACT_APP_EXPORT_LABEL,

  ws1: Number(env.REACT_APP_ACTIVATE_WS1) === 1,
  ws2: Number(env.REACT_APP_ACTIVATE_WS2) === 1,
  ws3: Number(env.REACT_APP_ACTIVATE_WS3) === 1,
  ws4: Number(env.REACT_APP_ACTIVATE_WS4) === 1,
  defaultWS: Number(env.REACT_APP_DEFAULT_WS),
};
