import React from 'react';
import {
  apiErrors,
  dmpErrors,
  softwareErrors,
  vitaleErrors,
  errorTypes as connectorErrorTypes,
  esrestSoftwareErrors, jwtErrors,
} from 'dmpconnectjsapp-base/errors';
import { errorActions } from './errorActions';
import { closedSectionErrorHandler, handleLicenseError, insiOperationFailedHandler } from '../sagas/errorHandlersSagas';
import { tlsiErrors } from './errorConstants';

export const errorSeverities = {
  LOW_SEVERITY: 'LOW_SEVERITY',
  HIGH_SEVERITY: 'HIGH_SEVERITY',
};

export const errorTypes = connectorErrorTypes;

export const dmpConnectTLSiErrorDefaultDescription = {
  title: 'Une erreur s\'est produite lors de l\'accès au téléservice',
  message: 'Merci de transmettre au service technique les informations ci-dessous.',
  action: errorActions.NONE,
  severity: errorSeverities.HIGH_SEVERITY,
  showDetails: true,
  modal: true,
};
export const dmpConnectJSErrorDefaultDescription = {
  title: 'Un bug logiciel s\'est produit.',
  message: 'Merci de transmettre au service technique les informations ci-dessous.',
  action: errorActions.CLOSE_SESSION,
  severity: errorSeverities.HIGH_SEVERITY,
  showDetails: true,
  modal: true,
};

export const dmpErrorDefaultDescription = {
  title: 'Une erreur s\'est produite lors de l\'accès au DMP.',
  action: errorActions.GO_TO_DASHBOARD,
  severity: errorSeverities.LOW_SEVERITY,
  showDetails: true,
  modal: true,
};

export const xdsDmpErrorDefaultDescription = {
  title: 'Une erreur s\'est produite lors de l\'accès à l\'entrepôt de documents DMP.',
  message: 'Des modifications (suppressions, changements de visibilité, etc.) ont pu se produire sur le DMP depuis qu\'il a été ouvert. Merci de rafraîchir l\'interface, ou de réouvrir le DMP si le problème persiste.',
  severity: errorSeverities.LOW_SEVERITY,
  action: errorActions.REFRESH_PAGE,
  showDetails: false,
};

export const vitaleErrorDefaultDescription = {
  title: 'Une erreur technique s\'est produite lors de la lecture de la carte.',
  message: 'Merci de transmettre au service technique les informations ci-dessous.',
  action: errorActions.REFRESH_PAGE,
  severity: errorSeverities.LOW_SEVERITY,
  showDetails: true,
  modal: true,
};


export const curlErrorDefaultDescription = {
  title: 'Une erreur réseau s\'est produite.',
  message: 'Merci de transmettre au service technique les informations ci-dessous.',
  showDetails: true,
  action: errorActions.CLOSE_SESSION,
  severity: errorSeverities.HIGH_SEVERITY,
};


export const dmpConnectorErrors = {
  [errorTypes.ApiErrors]: {
    [apiErrors.INVALID_CALL]: {
      title: 'Une erreur technique s\'est produite.',
      message: 'Merci de transmettre au service technique les informations ci-dessous.',
      action: errorActions.NONE,
      showDetails: true,
    },
    [apiErrors.INVALID_PARAMETER]: {
      title: 'Un bug logiciel s\'est produit.',
      message: 'Un paramètre est invalide',
      action: errorActions.REFRESH_PAGE,
      showDetails: true,
    },
    [apiErrors.OPERATION_FAILED]: {
      title: 'Une erreur technique s\'est produite.',
      message: 'Merci de transmettre au service technique les informations ci-dessous.',
      action: errorActions.NONE,
      showDetails: true,
      modal: true,
      errorHandler: insiOperationFailedHandler,
    },
    [apiErrors.CONCURRENT_ACCESS_ERROR]: {
      title: 'Une erreur technique s\'est produite.',
      message: 'Le service DmpConnect-JS est bloqué et doit être redémarré manuellement ou via la superviseur présent en barre d\'icône système (systray). Merci de transmettre au service technique les informations ci-dessous.',
      action: errorActions.CLOSE_SESSION,
      showDetails: true,
    },
    [apiErrors.CPX_EXPIRED]: {
      title: 'La carte CPS est expirée.',
      message: '',
      modal: false,
    },
    [apiErrors.CPX_READ_ERROR]: {
      title: 'La lecture de la CPS a échoué.',
      message: '',
      modal: false,
    },
    [apiErrors.CPX_INVALIDPINCODE]: {
      title: 'Le code porteur saisi est invalide.',
      message: '',
      modal: false,
    },
    [apiErrors.CPX_BLOCKED]: {
      title: 'La carte CPS est bloquée suite à un trop grand nombre d\'erreurs de saisie du code porteur.',
      message: 'Il est nécessaire de la débloquer avec CPS Gestion (Windows) et votre code de réinitialisation personnel.',
      modal: false,
    },
    [apiErrors.CPX_MISSING]: {
      title: 'Pas de carte détectée dans le lecteur.',
      message: 'Merci de vérifier la configuration des lecteurs, ou d\'insérer la carte.',
      modal: false,
    },
    [apiErrors.CPX_STATUSCHANGED]: {
      title: 'Une erreur s’est produite avec la carte CPS.',
      message: 'Merci de transmettre au service technique les informations ci-dessous.\n',
      action: errorActions.CLOSE_SESSION,
      showDetails: true,
    },
    [apiErrors.CPX_UNKNOWNCARD]: {
      title: 'La carte insérée n\'est pas une CPS.',
      message: 'Veuillez vérifier que les cartes CPS et Vitale ne sont pas inversées par exemple.',
    },
    [apiErrors.LICENSE_INTEGRATOR_DISABLED]: {
      title: 'Votre licence d\'utilisation est expirée.',
      message: 'Merci de contacter le service commercial.',
      errorHandler: handleLicenseError,
    },
    [apiErrors.LICENSE_VERSION_DISABLED]: {
      title: 'Cette version du connecteur DmpConnect-JS n\'est plus utilisable.',
      message: 'Merci de le mettre à jour DmpConnect-JS..',
      errorHandler: handleLicenseError,
    },
    [apiErrors.LICENSE_USER_DISABLED]: {
      title: 'Votre licence d\'utilisation est invalide.',
      message: 'Merci de contacter le service commercial.',
      errorHandler: handleLicenseError,
    },
    [apiErrors.LICENSE_USER_DOES_NOT_EXISTS]: {
      title: 'Votre licence d\'utilisation est invalide.',
      message: 'Merci de contacter le service commercial.',
      errorHandler: handleLicenseError,
    },
    [apiErrors.LICENSE_UNKNOWN_STATE]: {
      title: 'Une erreur de validation de votre licence d\'utilisation s\'est produite.',
      message: 'Merci de contacter le service commercial.',
      errorHandler: handleLicenseError,
    },
    [apiErrors.INVALID_ins]: {
      title: 'L\'INS fourni est invalide.',
      message: 'L\'INS fourni est invalide.',
      modal: false,
    },
    [apiErrors.DEMO_CARD_IN_PROD]: {
      title: 'La carte est invalide',
      modal: true,
      message: 'Utiliser une carte de demo/test sur un environnement de production est interdit.',
      action: errorActions.NONE,
    },
    [apiErrors.NO_NIR_FOUND]: {
      message: 'Aucun INS-NIR attaché.',
      modal: false,
    },
    [apiErrors.VITALE_CHANGED]: {
      title: 'La carte vitale a changé',
      message: 'Veuillez relire la carte',
      modal: false,
      action: errorActions.NONE,
    },
  },
  [errorTypes.DMPErrors]: {
    [dmpErrors.DMPTooManyResult]: {
      title: 'Trop de résultats.',
      message: 'Veuillez affiner votre recherche.',
      modal: false,
    },
    [dmpErrors.DMPAccessDeniedByRightsService]: {
      message: 'Cette opération DMP est interdite.',
    },
    [dmpErrors.DMPClosed]: {
      message: 'Le DMP est fermé.',
    },
    [dmpErrors.DMPFound]: {
      message: 'Le DMP existe déjà.',
      modal: false,
    },
    [dmpErrors.DMPFound]: {
      message: 'Le DMP existe déjà.',
      modal: false,
    },
    [dmpErrors.DMPIdentityCertificationFailure]: {
      modal: false,
    },
    [dmpErrors.DMPPatientAccessNotFound]: {
      modal: false,
    },
    [dmpErrors.DMPPatientAccessOTPNotFound]: {
      modal: false,
    },
    [dmpErrors.DMPPatientAccessOTPDeleteForbidden]: {
      message: 'Interdiction de supprimer le canal OTP. Il faut toujours au moins un canal de défini.',
      modal: false,
    },
    [dmpErrors.DMPInvalidData]: {
      modal: false,
      showDetails: true,
    },
    [dmpErrors.DMPPatientNotFound]: {
      message: 'Patient inconnu (DMP non trouvé).',
      modal: false,
    },
  },
  [errorTypes.DmpConnectJsSoftwareErrors]: {
    3: {
      title: 'Une erreur s\'est produite',
      message: 'La session a expiré',
      action: errorActions.CLOSE_SESSION,
      errorHandler: closedSectionErrorHandler,
    },
  },
  [errorTypes.SoftwareErrors]: {
    [softwareErrors.WEBSOCKET_TIMEOUT]: {
      title: 'Délai de réponse dépassé',
      message: 'Le connecteur DmpConnect-JS n\'a pas répondu dans les temps.',
      action: errorActions.REFRESH_PAGE,
      showDetails: true,
      modal: true,
    },
    [softwareErrors.WEBSOCKET_ERROR]: {
      title: 'Erreur de connexion',
      message: 'Erreur de connexion au connecteur DMPConnect-JS.',
      action: errorActions.REFRESH_PAGE,
      showDetails: true,
      modal: true,
    },
    [softwareErrors.CPX_REMOVED]: {
      title: 'Une erreur s\'est produite',
      message: 'La carte CPS a été retirée',
      severity: errorSeverities.HIGH_SEVERITY,
      action: errorActions.CLOSE_SESSION,
    },
    [softwareErrors.INVALID_SEARCH_PARAMS]: {
      title: 'Une erreur s\'est produite',
      message: 'Les paramètres fournis sont invalides',
      action: errorActions.NONE,
      showDetails: true,
    },
    [softwareErrors.INS_NIR_INVALID_FORMAT]: {
      title: 'Une erreur s\'est produite',
      message: (
        <>
          L&apos;insNir fourni est invalide.
          <br />
          Le format attendu est : 15 chiffres + 1 lettre
        </>
      ),
      action: errorActions.NONE,
      showDetails: true,
    },
    [softwareErrors.INSC_INVALID_FORMAT]: {
      title: 'Une erreur s\'est produite',
      message: (
        <>
          L&apos;insC fourni est invalide.
          <br />
          Le format attendu est : 22 chiffres
        </>
      ),
      action: errorActions.NONE,
      showDetails: true,
    },
    [softwareErrors.DMPCONNECTJS_MISSING_OR_UNREACHABLE]: {
      title: 'DmpConnect-JS inaccessible',
      message: (
        <>
          Cette application nécessite l&apos;installation préalable du composant DmpConnect-JS (non
          trouvé).
          <br />
          <br />
          <a href="https://www.icanopee.fr" target="_blank" rel="noopener noreferrer">
            Lien de
            téléchargement
          </a>
        </>
      ),
      action: errorActions.REFRESH_PAGE,
      modal: true,
      showDetails: false,
    },
    [softwareErrors.DMPCONNECJS_WRONG_VERSION]: {
      title: 'Version DmpConnect-JS',
      message:
  <>
    Une version trop ancienne du connecteur DmpConnect-JS est installée.
    <br />
    <br />
    <a href="https://www.icanopee.fr" target="_blank" rel="noopener noreferrer">
      Lien de
      téléchargement
    </a>
  </>,
      action: errorActions.CLOSE_SESSION,
      showDetails: true,
    },
    [softwareErrors.UNEXPECTED_ERROR]: {
      title: 'Une erreur technique s\'est produite.',
      message: 'Merci de transmettre au service technique les informations ci-dessous.',
      action: errorActions.REFRESH_PAGE,
      showDetails: true,
      modal: true,
      severity: errorSeverities.HIGH_SEVERITY,
    },
    [softwareErrors.DEMO_CPX_IN_PROD]: {
      title: 'Carte invalide',
      modal: true,
      message: 'Les cartes de démonstration ou de test ne permettent pas d\'accéder au DMP de production.',
      action: errorActions.LOGIN,
      severity: errorSeverities.HIGH_SEVERITY,
    },
    [softwareErrors.WRONG_DC_PARAMS_CONFIG]: {
      title: 'Problème de configuration',
      modal: true,
      message: 'L\'application n\'est pas autorisée à accéder au connecteur.',
      action: errorActions.CLOSE_SESSION,
      severity: errorSeverities.HIGH_SEVERITY,
    },
    [softwareErrors.PARAMS_INVALID_JSON]: {
      title: 'Paramètres URL',
      modal: true,
      showDetails: true,
      message: 'Le format des paramètres fournis est invalide',
      action: errorActions.CLOSE_SESSION,
      severity: errorSeverities.HIGH_SEVERITY,
    },
    [softwareErrors.LOGINCHECK_MISSING_VALUE]: {
      title: 'Vérification Identité',
      modal: true,
      showDetails: true,
      message: 'Les paramètres de vérification d\'identité fournis sont incomplets',
      action: errorActions.CLOSE_SESSION,
      severity: errorSeverities.HIGH_SEVERITY,
    },
    [softwareErrors.CONNECTOR_REST_UNREACHABLE]: {
      title: 'Connecteur inaccessible',
      message: 'Le connecteur ne semble pas actif',
      action: errorActions.NONE,
      showDetails: false,
      severity: errorSeverities.HIGH_SEVERITY,
    },
    [softwareErrors.API_LOGIN_MISSING_VALUE]: {
      title: 'Erreur de connexion',
      message: 'Des informations nécessaires à la connexion sont manquantes',
      action: errorActions.LOGIN,
      showDetails: true,
      severity: errorSeverities.HIGH_SEVERITY,
    },
    [softwareErrors.AIR_AUTH_MODE_INVALID]: {
      title: 'Problème de configuration',
      message: 'Le mode d\'authentification air fourni est invalide',
      action: errorActions.LOGIN,
      showDetails: true,
      severity: errorSeverities.HIGH_SEVERITY,
    },
    [softwareErrors.NO_PRACTICE_LOCATIONS]: {
      title: 'Carte invalide',
      message: (
        <>
          La carte CPx ne contient aucune situation d&apos;exercice.
          <br />
          L&apos;application ne peut fonctionner dans ce cas.
        </>
      ),
      severity: errorSeverities.HIGH_SEVERITY,
      action: errorActions.CLOSE_SESSION,
      showDetails: false,
    },
    [softwareErrors.INSI_EXPORT_ERROR]: {
      modal: false,
    },
  },
  [errorTypes.dmpConnectRestSoftwareErrors]: {
    [esrestSoftwareErrors.TECHNICAL_ERROR]: {
      title: 'Une erreur technique s\'est produite.',
      message: 'Merci de transmettre au service technique les informations ci-dessous.',
      action: errorActions.CLOSE_SESSION,
      showDetails: true,
    },
    [esrestSoftwareErrors.FUNCTIONAL_ERROR]: {
      title: 'Une erreur fonctionnelle s\'est produite.',
      message: 'Merci de transmettre au service technique les informations ci-dessous.',
      action: errorActions.CLOSE_SESSION,
      showDetails: true,
    },
  },
  [errorTypes.VitaleErrors]: {
    [vitaleErrors.ERR_CARTE_ABSENTE]: {
      title: 'Aucune carte dans le lecteur',
      message: '',
      modal: false,
    },
    [vitaleErrors.ERR_CARTE]: {
      title: 'Erreur lors la lecture de la carte',
      message: '',
      modal: false,
    },
    [vitaleErrors.ERR_LECTEUR]: {
      title: 'Erreur avec le lecteur de carte',
      message: '',
      modal: false,
    },
    [vitaleErrors.ERR_CARTE_INCONNUE]: {
      title: 'Carte inconnue',
      message: '',
      modal: false,
    },
    [vitaleErrors.ERR_CARTE_MUETTE]: {
      title: 'Carte muette',
      message: '',
      modal: false,
    },
    [vitaleErrors.ERR_CARTE_INVALIDE]: {
      title: 'Carte invalide',
      message: '',
      modal: false,
    },
    [vitaleErrors.ERR_TIMEOUT]: {
      title: 'Aucune carte dans le lecteur',
      message: '',
      modal: false,
    },
  },
  [errorTypes.TLSiErrors]: {
    [tlsiErrors.SIR_INFRASTRUCTURE_ERROR]: {
      title: 'Erreur d\'infrastructure SIR',
      // message: 'La carte CPS n\'a pas été reconnue, veuillez réessayer',
      modal: true,
      showDetails: true,
      severity: errorSeverities.LOW_SEVERITY,
    },
  },
  [errorTypes.JWTErrors]: {
    [jwtErrors.INVALID_TOKEN]: {
      title: 'Erreur JWT',
      message: 'Le jeton fourni n\'est pas valide.',
      action: errorActions.LOGIN,
      severity: errorSeverities.HIGH_SEVERITY,
      modal: true,
      showDetails: false,
    },
    [jwtErrors.EXPIRED_TOKEN]: {
      title: 'Erreur JWT',
      message: 'Le jeton fourni a expiré.',
      action: errorActions.LOGIN,
      severity: errorSeverities.HIGH_SEVERITY,
      modal: true,
      showDetails: false,
    },
    [jwtErrors.MISSING_CLAIMS]: {
      title: 'Erreur JWT',
      message: 'Des données sont manquantes dans le jeton.',
      action: errorActions.LOGIN,
      severity: errorSeverities.HIGH_SEVERITY,
      modal: true,
      showDetails: false,
    },
    [jwtErrors.INVALID_SIGNATURE]: {
      title: 'Erreur JWT',
      message: 'La signature du jeton est invalide.',
      action: errorActions.LOGIN,
      severity: errorSeverities.HIGH_SEVERITY,
      modal: true,
      showDetails: false,
    },
    [jwtErrors.EMPTY_TOKEN]: {
      title: 'Erreur JWT',
      message: 'Aucun jeton fourni',
      action: errorActions.LOGIN,
      severity: errorSeverities.HIGH_SEVERITY,
      modal: true,
      showDetails: false,
    },
  },
};
