import { dmpconnectUserActionConstants } from '../constants';
import { getSessionId } from '../helpers';

/**
 *
 * @param loginReferer
 * @returns {{loginReferer: *, type: string}}
 */
export const setLoginReferer = loginReferer => ({
  type: dmpconnectUserActionConstants.DMPC_SET_LOGIN_REFERER,
  loginReferer,
});

/**
 *
 * @returns {{type: string}}
 */
export const clearLoginReferer = () => ({
  type: dmpconnectUserActionConstants.DMPC_CLEAR_LOGIN_REFERER,
});

export const login = ({
  pinCode,
  practiceSetting,
  cpsPracticeLocationIndice,
  customPracticeLocationStructureName,
  customPracticeLocationStructureIdType,
  customPracticeLocationStructureId,
  customPracticeLocationActivitySector,
  auto = false,
}) => ({
  type: dmpconnectUserActionConstants.DMPC_LOGIN,
  pinCode,
  practiceSetting,
  cpsPracticeLocationIndice,
  auto,
  customPracticeLocationStructureName,
  customPracticeLocationStructureIdType,
  customPracticeLocationStructureId,
  customPracticeLocationActivitySector,
});

/**
 *
 * @returns {function(*, *): *}
 */
export const logout = redirectTo => (dispatch, getState) => dispatch(
  {
    type: dmpconnectUserActionConstants.DMPC_LOGOUT,
    sessionId: getSessionId(getState()),
    redirectTo,
  },
);

/**
 *
 * @returns {{type: string}}
 */
export const logoutSuccess = () => ({
  type: dmpconnectUserActionConstants.DMPC_LOGOUT_SUCCESS,
});

/**
 *
 * @returns {{type: string}}
 */
export const loginSuccess = () => ({
  type: dmpconnectUserActionConstants.DMPC_LOGIN_SUCCESS,
});

export const setLoginStepTwo = status => ({
  type: dmpconnectUserActionConstants.DMPC_SET_LOGIN_STEP_TWO,
  status,
});


export const setInsParams = params => ({
  type: dmpconnectUserActionConstants.DMPC_SET_INS_PARAMS,
  params,
});
export const setInsParam = (key, value) => ({
  type: dmpconnectUserActionConstants.DMPC_SET_INS_PARAM,
  key,
  value,
});

export const setCheckIdentityParams = params => ({
  type: dmpconnectUserActionConstants.DMPC_CHECK_ID_PARAMS,
  params,
});
export const setCheckIdentityParam = (key, value) => ({
  type: dmpconnectUserActionConstants.DMPC_CHECK_ID_PARAM,
  key,
  value,
});

export const esLogin = (userLogin, userPassword) => ({
  type: dmpconnectUserActionConstants.DMPC_ES_LOGIN,
  userLogin,
  userPassword,
});
export const setEsUser = user => ({
  type: dmpconnectUserActionConstants.DMPC_SET_ES_USER,
  user,
});

export const esLoginFailed = message => ({
  type: dmpconnectUserActionConstants.DMPC_ES_LOGIN_FAILED,
  message,
});
export const esLoginSuccess = () => ({
  type: dmpconnectUserActionConstants.DMPC_ES_LOGIN_SUCCESS,
});

export const setExpiredSession = expiredSession => ({
  type: dmpconnectUserActionConstants.EXPIRED_SESSION,
  expiredSession,
});

export const setPinCode = pinCode => ({
  type: dmpconnectUserActionConstants.SET_PIN_CODE,
  pinCode,
});
