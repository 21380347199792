import { LOCATION_CHANGE, push, replace } from 'connected-react-router';
import { xml2js } from 'xml-js';

import {
  call, put, select, take, takeEvery,
} from 'redux-saga/effects';

import { API_TYPES } from 'dmpconnectjsapp-base/constants';
import { getApiType, getConfigurationValue } from 'dmpconnectjsapp-base/helpers/accessors';
import commands from 'dmpconnectjsapp-base/actions/config/commands';
import {
  clearSection,
  resetESConfiguration,
  resetGlobalConfiguration,
  resetINSiConfiguration,
  setPersistedConnectorConfiguration,
  stopCpxMonitoring,
} from 'dmpconnectjsapp-base/actions';
import { esLoginTypes } from 'dmpconnectjsapp-base/reducers/dmpconnectESConfiguration';
import { formatGetCardParams } from 'dmpconnectjsapp-base/actions/config/commandParamsFormatters';
import { apiErrors } from 'dmpconnectjsapp-base/errors';
import {
  dmpCommandFailureContextualizedType,
  dmpCommandSuccessContextualizedType,
  errorVitaleCardChanged,
  esLoginSuccess,
  getAction,
  getCpxCardType,
  getRestPcscReaders,
  getSessionState,
  insi_getInsFromVitaleCard, logoutSuccess,
  readVitaleCard,
  resetDisplayConfigurationFromEnv,
  resetPersistedAppConfigurationFromEnv,
  setPersistedAppConfiguration, setUrlProcessed,
} from '../actions';

import {
  dmpconnectActionConstants,
  dmpconnectConfigurationActionConstants,
  dmpconnectUserActionConstants,
  readerSlotTypes,
} from '../constants';


import {
  checkIfUserIsLoggedIn, esLoginProcess, handleReadCpxCard, loginProcess, logoutProcess,
} from './securitySagas';
import { createError } from '../errors';
import { errorTypes } from '../errors/errorConfiguration';
import {
  b64DecodeUnicode, mergeSearchAndHash, parseQueryString,
} from '../utils/dataUtils';
import {
  checkPendingBatches,
  exportResults,
  handleINSiBatchGetResult,
  handleINSiBatchSubmitResult,
} from './insiBatchSaga';
import env from '../../envVariables';
import { getDmpconnectESConfiguration } from '../helpers';
import { clearPersistantDataFromState, getPersistantDataFromState, setPersistantDataToState } from './persistantDataSaga';
import {
  connectorConfigFromEnv,
  displayConfigFromEnv,
  esConfigFromEnv,
  insiConfigFromEnv,
} from '../../reducers/configFromEnv';
import { parseXmlToJsResult } from '../utils/xml';
import { apiSections } from '../constants/apiConstants';
import { legacyLocationChange } from './legacyLocationChange';
import { processGenericParams } from './locationChange';

const getVitaleSerialNumber = (state, section) => {
  const {
    dmpconnect: {
      [section]: {
        vitaleSerialNumber = '',
      } = {},
    },
  } = state;
  return vitaleSerialNumber;
};

const getCpxType = function* ({ data, context: { noCardTypeCheck = false } }) {
  if (!noCardTypeCheck) {
    const { i_cpxCard: cpxCardHandle = 0 } = data;
    if (cpxCardHandle > 0) yield put(getCpxCardType(cpxCardHandle));
  }
};

const readVitaleCardAfterGetVitaleSuccess = function* () {
  yield put(readVitaleCard());
};

const getVitaleCardXmlContent = function* ({ data, context: { section } }) {
  yield put(getSessionState());
  const sessionStateResult = yield take([
    dmpCommandSuccessContextualizedType(apiSections.SESSION_STATE_SECTION),
    dmpCommandFailureContextualizedType(apiSections.SESSION_STATE_SECTION),
  ]);

  if (sessionStateResult && sessionStateResult.type === dmpCommandSuccessContextualizedType(apiSections.SESSION_STATE_SECTION)) {
    // get vitale xml
    const { i_vitaleCard: vitaleHandle = 0 } = sessionStateResult.data;
    if (vitaleHandle > 0) {
      yield put(getAction(
        commands.getVitaleXmlContent,
        apiSections.VITALE_XML_CONTENT_SECTION,
        vitaleHandle,
      ));

      const result = yield take([
        dmpCommandSuccessContextualizedType(apiSections.VITALE_XML_CONTENT_SECTION),
        dmpCommandFailureContextualizedType(apiSections.VITALE_XML_CONTENT_SECTION),
      ]);

      if (result && result.type === dmpCommandSuccessContextualizedType(apiSections.VITALE_XML_CONTENT_SECTION)) {
        const { data: { s_utf8String } } = result;
        const xmlContent = b64DecodeUnicode(s_utf8String);
        const xml = xml2js(xmlContent, { compact: true });

        const xmlToJs = parseXmlToJsResult(xml);

        const {
          T_AsnDonneesVitale: {
            tech: {
              numSerie: {
                _text: numSerie = '',
              } = {},
            } = {},
            listeBenef: {
              T_AsnBeneficiaire: patients = [],
            } = {},
          } = {},
        } = xml;

        let patientsArray = patients;
        if (!Array.isArray(patients)) {
          patientsArray = [patients];
        }

        let RightHolderVitaleData = {};

        const patientsData = patientsArray.map((patient) => {
          const {
            amo: {
              qualBenef: {
                _text: qualBenef = '',
              } = {},
              centreCarte: {
                _text: centreCarte = '',
              } = {},
              codeGestion: {
                _text: codeGestion = '',
              } = {},
              codeRegime: {
                _text: codeRegime = '',
              } = {},
            } = {},
            ident: {
              dateCertification: {
                _text: dateCertification = '',
              } = {},
              naissance: {
                date: {
                  _text: date = '',
                } = {},
                dateEnCarte: {
                  _text: dateEnCarte = '',
                } = {},
              } = {},
              nir: {
                _text: nir = '',
              } = {},
              nirCertifie: {
                _text: nirCertifie = '',
              } = {},
              nomPatronymique: {
                _text: nomPatronymique = '',
              } = {},
              nomUsuel: {
                _text: nomUsuel = '',
              } = {},
              prenomUsuel: {
                _text: prenomUsuel = '',
              } = {},
              rangDeNaissance: {
                _text: rangDeNaissance = '',
              } = {},

            } = {},
          } = patient;

          const splittedNir = nir.split(' ');
          const splittedNirCertifie = nirCertifie.split(' ');

          if (Number(qualBenef) === 0) {
            RightHolderVitaleData = {
              s_birthName: nomPatronymique,
              NirData: {
                Nir: {
                  s_nir: splittedNir[0],
                  s_key: splittedNir[1],
                },
              },
            };
          }

          return {
            BeneficiaryVitaleData: {
              s_name: nomUsuel,
              s_birthName: nomPatronymique,
              s_givenName: prenomUsuel,
              s_birthday: date || dateEnCarte,
              i_rank: Number(rangDeNaissance),
              i_qualBenef: Number(qualBenef),
              NirData: {
                Nir: {
                  s_nir: splittedNir[0],
                  s_key: splittedNir[1],
                },
                CertifiedNir: {
                  s_nir: splittedNirCertifie && splittedNirCertifie.length === 2 ? splittedNirCertifie[0] : '',
                  s_key: splittedNirCertifie && splittedNirCertifie.length === 2 ? splittedNirCertifie[1] : '',
                },
                s_certificationDate: dateCertification,
              },
              HealthCoverage: {
                s_regime: codeRegime,
                s_desk: codeGestion,
                s_center: centreCarte,
              },
            },
            s_serialNumber: numSerie,
          };
        });


        yield put({
          ...result,
          type: 'DMPC_COMMAND_SUCCESS',
          data: {
            ...result.data,
            xmlPatients: patientsData,
            RightHolderVitaleData,
            xmlToJs,
          },
        });
      }
    }
  }
};

function* getInsFromVitaleCardProcess({ vitaleIndex }) {
  yield put(readVitaleCard(true));
  yield take(dmpCommandSuccessContextualizedType(apiSections.VITALE_XML_CONTENT_CHECK_SECTION));
  const readSerialNumber = yield select(getVitaleSerialNumber, apiSections.VITALE_CARD_SECTION);
  const checkSerialNumber = yield select(getVitaleSerialNumber, apiSections.VITALE_CARD_CHECK_SECTION);

  if (readSerialNumber !== '' && checkSerialNumber !== '' && readSerialNumber === checkSerialNumber) {
    yield put(insi_getInsFromVitaleCard(vitaleIndex));
  } else {
    const error = createError(
      errorTypes.ApiErrors,
      apiErrors.VITALE_CHANGED,
    );
    yield put(errorVitaleCardChanged(error));
  }
}

const handleLoginProcess = function* (action) {
  const apiType = yield select(getApiType);

  if (apiType === API_TYPES.REST) {
    yield put(esLoginSuccess());
    yield put(push('/search'));
  } else {
    yield call(loginProcess, action);
  }
};

const handleEsLoginProcess = function* (action) {
  const apiType = yield select(getApiType);
  if (apiType === API_TYPES.REST) {
    yield put(esLoginSuccess());
    yield put(push('/search'));
  } else {
    yield call(esLoginProcess, action);
  }
};

const handleRequestPcscReaders = function* ({ synchronous }) {
  const apiType = yield select(getApiType);

  if (apiType === API_TYPES.WS) {
    yield put(getAction(
      commands.getPcscReaders,
      apiSections.PCSC_READERS_SECTION,
      null,
      { synchronous: true },
    ));
  } else {
    yield put(getRestPcscReaders());
  }
};

const getCpxHpInfo = function* (reader, index) {
  // CPX card object creation
  yield put(stopCpxMonitoring());
  yield put(getAction(
    commands.getCpxCard,
    apiSections.CPX_SECTION,
    formatGetCardParams(index),
    { synchronous: true, silentError: true },
  ));

  const getCpxCardResult = yield take(
    [
      dmpCommandSuccessContextualizedType(apiSections.CPX_SECTION),
      dmpCommandFailureContextualizedType(apiSections.CPX_SECTION),
    ],
  );

  if (getCpxCardResult.type === dmpCommandSuccessContextualizedType(apiSections.CPX_SECTION)) {
    yield put(getAction(
      commands.getCpxHpInfos,
      'cpxHpInfos',
      null,
      {
        subSection: `reader_index_${index}`,
        contextParams: { index },
        silentError: true,
      },
    ));
  }
};

const handlePcsReadersSuccess = function* (action) {
  const { data: { Readers = [] } } = action;
  const apiType = yield select(getApiType);
  if (apiType === API_TYPES.WS) {
    yield put(clearSection('cpxHpInfos'));
    for (let index = 0; index < Readers.length; index += 1) {
      const reader = Readers[index];
      if (reader.i_slotType === readerSlotTypes.CPS) {
        yield call(getCpxHpInfo, reader, index);
      }
    }
  }
};

const resetAllConfigHandler = function* () {
  yield put(resetGlobalConfiguration(connectorConfigFromEnv));
  yield put(resetESConfiguration(esConfigFromEnv));
  yield put(resetINSiConfiguration(insiConfigFromEnv));
  yield put(resetDisplayConfigurationFromEnv(displayConfigFromEnv));
  yield put(resetPersistedAppConfigurationFromEnv());
  yield put(setPersistedConnectorConfiguration('onlyGeneratedConnectorJWT', Number(env.REACT_APP_GENERATED_CONNECTOR_JWT) === 1));
  yield put(setPersistedConnectorConfiguration('esRestVitale', Number(env.REACT_APP_ES_REST_ENABLE_VITALE_CARD_READING) === 1));
};

export const handleCallbacks = function* () {
  yield takeEvery(dmpCommandSuccessContextualizedType(apiSections.SESSION_STATE_SECTION), getCpxType);
  yield takeEvery(dmpCommandSuccessContextualizedType(apiSections.VITALE_SECTION), readVitaleCardAfterGetVitaleSuccess);
  yield takeEvery(dmpCommandSuccessContextualizedType(apiSections.VITALE_CARD_SECTION), getVitaleCardXmlContent);
  yield takeEvery(dmpconnectActionConstants.DMPC_GET_INS_FROM_VITALE_CARD_PROCESS, getInsFromVitaleCardProcess);
  yield takeEvery(dmpCommandSuccessContextualizedType(apiSections.INSI_SUBMIT_IDENTITY_BATCH), handleINSiBatchSubmitResult);
  yield takeEvery(dmpCommandSuccessContextualizedType(apiSections.INSI_RESULT_IDENTITY_BATCH), handleINSiBatchGetResult);
  yield takeEvery(dmpconnectActionConstants.DMPC_INSI_CHECK_PENDING_BATCHES, checkPendingBatches);
  yield takeEvery(dmpconnectActionConstants.DMPC_INSI_EXPORT_RESULT, exportResults);
  yield takeEvery(dmpconnectUserActionConstants.DMPC_LOGIN, handleLoginProcess);
  yield takeEvery(dmpconnectUserActionConstants.DMPC_LOGOUT, logoutProcess);
  yield takeEvery(dmpconnectUserActionConstants.DMPC_ES_LOGIN, handleEsLoginProcess);
  yield takeEvery(dmpconnectActionConstants.DMPC_READ_CPX_PROCESS, handleReadCpxCard);
  yield takeEvery(dmpconnectActionConstants.DMPC_REQUES_PCSC_READERS, handleRequestPcscReaders);
  yield takeEvery(dmpconnectActionConstants.DMPC_GET_PERSISTANT_DATA_FROM_LOCALSTORAGE, getPersistantDataFromState);
  yield takeEvery(dmpconnectActionConstants.DMPC_SET_PERSISTANT_DATA_TO_LOCALSTORAGE, setPersistantDataToState);
  yield takeEvery(dmpconnectActionConstants.DMPC_CLEAR_PERSISTANT_DATA_FROM_LOCALSTORAGE, clearPersistantDataFromState);
  yield takeEvery(dmpCommandSuccessContextualizedType(apiSections.PCSC_READERS_SECTION), handlePcsReadersSuccess);
  yield takeEvery(dmpconnectConfigurationActionConstants.DMPC_RESET_ALL_CONFIGURATION_FROM_ENV, resetAllConfigHandler);
};

export const handleLocationChange = function* () {
  while (true) {
    const { payload } = yield take(LOCATION_CHANGE);
    const esConfig = yield select(getDmpconnectESConfiguration);
    const esLoginType = getConfigurationValue('loginType', esConfig);

    const { location: { pathname, hash, search }, action } = payload;
    if (pathname === '/login-token') {
      yield put(logoutSuccess());
    }
    const cleanedHash = hash.slice(1);
    const cleanedSearch = search.slice(1);
    const mergedSearchAndHash = mergeSearchAndHash(cleanedSearch, cleanedHash);
    const parameters = parseQueryString(mergedSearchAndHash);
    const { user, person } = parameters || {};
    const loggedIn = yield call(checkIfUserIsLoggedIn, payload);

    yield call(processGenericParams, parameters, loggedIn, pathname);
    yield call(legacyLocationChange, parameters, loggedIn, pathname, action);

    if (esLoginType === esLoginTypes.API && user && person) {
      yield put(setPersistedAppConfiguration('apiLoginValues', { psInternalId: user, patientInternalId: person }));
      yield put(replace('/'));
    }
    if (action === 'POP') {
      yield put(setUrlProcessed(true));
    }
  }
};
