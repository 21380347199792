import {
  apiErrors as apiErrorsBase,
  dmpErrors as dmpErrorsBase,
  jwtErrors as jwtErrorsBase,
  vitaleErrors as vitaleErrorsBase,
  softwareErrors as softwareErrorsBase,
} from 'dmpconnectjsapp-base/errors';

export const apiErrors = apiErrorsBase;
export const dmpErrors = dmpErrorsBase;
export const jwtErrors = jwtErrorsBase;
export const vitaleErrors = vitaleErrorsBase;
export const softwareErrors = softwareErrorsBase;

export const jsonWenTokenErrorsToJwtErrors = {
  TokenExpiredError: jwtErrorsBase.EXPIRED_TOKEN,
  InvalidSignatureError: jwtErrorsBase.INVALID_SIGNATURE,
};
export const jwtIframeParentErrors = {
  [jwtErrorsBase.EXPIRED_TOKEN]: 'expired_token',
  [jwtErrorsBase.INVALID_SIGNATURE]: 'invalid_token_signature',
  [jwtErrorsBase.MISSING_CLAIMS]: 'missing_token_claims',
  [jwtErrorsBase.INVALID_TOKEN]: 'invalid_token',
};

export const tlsiErrors = {
  SIR_INFRASTRUCTURE_ERROR: 4096,
};
