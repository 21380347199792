import {
  setESConfiguration,
  setModalError,
  setPersistedConnectorConfiguration,
  setTseConfiguration,
} from 'dmpconnectjsapp-base/actions';
import { toast } from 'react-toastify';
import React from 'react';
import { call, put, select } from 'redux-saga/effects';
import { push, replace } from 'connected-react-router';
import { getConfigurationValue, getDmpconnectPersistedConnectorConfig } from 'dmpconnectjsapp-base/helpers/accessors';
import { generateAndSaveToken } from 'dmpconnectjsapp-base/sagas/utilsSagas';
import * as yup from 'yup';
import { b64DecodeUnicode, isUrlValid } from '../utils/dataUtils';
import env from '../../envVariables';
import {
  setCheckIdentityParams,
  setExportId,
  setInsParams,
  setPanel,
  setPersistedAppConfiguration,
} from '../actions';
import { createError, createErrorDetails, createModalError } from '../errors';
import { errorTypes } from '../errors/errorConfiguration';
import { softwareErrors } from '../errors/errorConstants';
import { PANELS } from '../constants';

function* checkAndSetTseId(param, stateKey, successMessage, errorMessage) {
  try {
    const decodedParam = b64DecodeUnicode(param);
    yield put(setTseConfiguration(stateKey, decodedParam));
    toast.dismiss(stateKey);
    toast.success(successMessage, {
      autoClose: 2000,
      toastId: stateKey,
    });
  } catch (e) {
    toast.error((
      <div>
        <div className="font-weight-bold">Erreur</div>
        <span>{errorMessage}</span>
      </div>
    ), {
      autoClose: false,
      toastId: stateKey,
    });
  }
}

function* checkAndSetUrl(urlParam, stateKey, successMessage, errorMessage) {
  try {
    const url = b64DecodeUnicode(urlParam);
    if (isUrlValid(url)) {
      yield put(setPersistedAppConfiguration(stateKey, url));
      toast.dismiss(stateKey);
      toast.success(successMessage, {
        autoClose: 2000,
        toastId: stateKey,
      });
    } else {
      throw new Error('Url is invalid');
    }
  } catch (e) {
    toast.error((
      <div>
        <div className="font-weight-bold">Erreur</div>
        <span>{errorMessage}</span>
      </div>
    ), {
      autoClose: false,
      toastId: stateKey,
    });
  }
}

function* handleConnectorJwt(connectorJwt) {
  const connectorConfig = yield select(getDmpconnectPersistedConnectorConfig);
  const onlyGeneratedConnectorJWT = getConfigurationValue('onlyGeneratedConnectorJWT', connectorConfig);
  const values = JSON.parse(b64DecodeUnicode(connectorJwt));
  const { jwt, jwtSecret } = values;

  if (jwt) {
    yield put(setPersistedConnectorConfiguration('connectorJWT', connectorJwt));
  } else if (jwtSecret && !onlyGeneratedConnectorJWT) {
    yield call(generateAndSaveToken, jwtSecret);
  }
}

export const handleLoginCheck = function* (logincheck) {
  try {
    const values = b64DecodeUnicode(logincheck);

    const requiredValues = (env.REACT_APP_ES_LOGIN_CHECK_REQUIRED_VALUES || 'name,given,rpps').split(',');
    const loginCheckOK = values && requiredValues.every(val => val in values);

    if (loginCheckOK) {
      yield put(setPersistedAppConfiguration('logincheck', { ...values }));
    } else {
      // show error modal
      const error = createError(errorTypes.SoftwareErrors, softwareErrors.LOGINCHECK_MISSING_VALUE);
      const details = [
        createErrorDetails('provided', logincheck),
        createErrorDetails('required', { properties: requiredValues.join(', ') }),
      ];
      const modalError = createModalError(error, details);
      yield put(setModalError(modalError));
    }
  } catch (e) {
    toast.error((
      <div>
        <div className="font-weight-bold">Erreur</div>
        <span>Les données de vérifications fournies sont invalides</span>
      </div>
    ), {
      autoClose: false,
    });
  }
};
const handleEsSettings = function* (settings) {
  // const activitySectors = yield select(getInteropCodesFromState, 'activitySectors');
  // const practiceSettings = yield select(getInteropCodesFromState, 'practiceSettings');

  const validator = yup.object({
    id: yup.string().required('L\'identifiant est manquant'),
    locationName: yup.string().required('Le nom de l\'établissement est manquant'),
    activitySector: yup.string()
      // .oneOf(
      //   activitySectors.map(ps => ps.code),
      //   ({ values }) => `Le secteur d'activité doit être une des valeurs suivantes : ${values}`,
      // )
      .required('Le secteur d\'activité est manquant'),
    practiceSetting: yup.string()
      // .oneOf(
      //   practiceSettings.map(ps => ps.code),
      //   ({ values }) => `Le cadre d'exercice doit être une des valeurs suivantes : ${values}`,
      // )
      .required('Le cadre d\'exercice est manquant'),
  });

  try {
    const values = b64DecodeUnicode(settings);

    validator.validateSync(values, { abortEarly: false });

    const {
      id,
      locationName,
      activitySector,
      practiceSetting,
    } = JSON.parse(values);

    yield put(setESConfiguration('es_id', id));
    yield put(setESConfiguration('practiceLocationName', locationName));
    yield put(setESConfiguration('activitySector', activitySector));
    yield put(setESConfiguration('practiceSetting', practiceSetting));

    toast.dismiss('es-config');
    toast.success('Configuration de l\'établissement mise à jour', {
      autoClose: 2000,
      toastId: 'es-config',
    });
  } catch (validationError) {
    const details = [
      createErrorDetails('Errors', validationError.inner.map(error => ({
        field: error.path,
        value: error.value,
        error: error.message,
      }))),
      createErrorDetails('provided', settings),
    ];
    const modalError = createModalError({
      i_apiErrorType: errorTypes.SoftwareErrors,
      i_apiErrorCode: softwareErrors.INVALID_ES_SETTINGS,
    }, details);

    yield put(setModalError(modalError));
  }
};

export function* legacyLocationChange(urlParameters, loggedIn, pathname, action) {
  let redirectToRoot = false;
  const parameters = urlParameters;

  // es settings
  if (parameters && parameters.esSettings) {
    yield call(handleEsSettings, parameters.esSettings);
    delete parameters.esSettings;
    redirectToRoot = true;
  }

  // check exportId param
  if (parameters && parameters.exportId) {
    yield put(setExportId(parameters.exportId));
    console.log('nouvel exportId pris en compte : ', parameters.exportId);
  }

  // check tseid param
  if (parameters && parameters.tseid) {
    yield call(
      checkAndSetTseId,
      parameters.tseid,
      'whoami',
      'Identifiant utilisateur TSE mis à jour',
      'L\'identifiant fourni n\'est pas valide',
    );
    delete parameters.tseid;
    redirectToRoot = true;
  }
  // check exporturl param
  if (parameters && parameters.exporturl) {
    yield call(
      checkAndSetUrl,
      parameters.exporturl,
      'resultExportUrl',
      'Adresse d\'export mise à jour',
      'L\'adresse d\'export fournie n\'est pas valide',
    );
    delete parameters.exporturl;
    redirectToRoot = true;
  }
  // check batchimporturl param
  if (parameters && parameters.batchimporturl) {
    yield call(
      checkAndSetUrl,
      parameters.batchimporturl,
      'batchImportUrl',
      'Adresse d\'import des lots d\'identités mise à jour',
      'L\'adresse d\'import des lots d\'identités fournie n\'est pas valide',
    );
    delete parameters.batchimporturl;
    redirectToRoot = true;
  }
  // check batchexporturl param
  if (parameters && parameters.batchexporturl) {
    yield call(
      checkAndSetUrl,
      parameters.batchexporturl,
      'batchExportUrl',
      'Adresse d\'export des lots d\'identités mise à jour',
      'L\'adresse d\'export des lots d\'identités fournie n\'est pas valide',
    );
    delete parameters.batchexporturl;
    redirectToRoot = true;
  }

  // check cpxInfoExportUrl param
  if (parameters && parameters.cpxInfoExportUrl) {
    yield call(
      checkAndSetUrl,
      parameters.cpxInfoExportUrl,
      'cpxInfoExportUrl',
      'Adresse d\'export des informations de la carte CPx mise à jour',
      'L\'adresse d\'export des informations de la carte CPx fournie n\'est pas valide',
    );
    delete parameters.cpxInfoExportUrl;
    redirectToRoot = true;
  }

  if (parameters.connectorJwt) {
    yield call(handleConnectorJwt, parameters.connectorJwt);
  }

  if (parameters && parameters.loginCheck) {
    yield call(handleLoginCheck, parameters.loginCheck);
    redirectToRoot = true;
  }

  if (loggedIn) {
    const decodedParams = {};
    if (Object.keys(parameters).length > 0) {
      Object.entries(parameters).forEach(([key, value]) => {
        if (key && value) {
          Object.assign(decodedParams, { [key]: decodeURIComponent(value.replace(/\+/g, ' ')) });
        }
      });
    }

    if (pathname.endsWith('search/vitale')) {
      yield put(setPanel(PANELS.FROM_VITALE_CARD));
      yield put(push('/search/'));
    } else if (pathname.endsWith('search/ins')) {
      yield put(setInsParams(decodedParams));
      yield put(setPanel(PANELS.MANUAL));
      yield put(push('/search/'));
    } else if (pathname.endsWith('search/checkIdentity')) {
      yield put(setCheckIdentityParams(decodedParams));
      yield put(setPanel(PANELS.VALIDATION));
      yield put(push('/search/'));
    }
  } else if (redirectToRoot) {
    yield put(replace('/'));
  }
}
