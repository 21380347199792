import merge from 'lodash.merge';
import { authenticationTypes } from 'dmpconnectjsapp-base/rules/accessRights';
import { dmpconnectApplicationActionConstants, dmpconnectUserActionConstants } from '../constants';
import { initialValues as initialInsParams } from '../helpers/insi/manualSearch';
import { initialValues as initialCheckIdParams } from '../helpers/insi/checkIdentity';

const initialState = {
  expiredSession: false,
  loggedIn: false,
  loginReferer: null,
  logoutPending: false,
  loginStepTwo: false,
  insParams: initialInsParams,
  identityParams: initialCheckIdParams,
  esLoginfailedMessage: {},
  requestESLogout: false,
  esUser: null,
  pinCode: null,
};

const setCheckIdParams = (state, params) => {
  const { identityParams, ...rest } = state;
  return {
    ...rest,
    identityParams: params,
  };
};
const setInsParams = (state, params) => {
  const { insParams, ...rest } = state;
  return {
    ...rest,
    insParams: params,
  };
};

export function dmpconnectUser(state = initialState, action) {
  switch (action.type) {
    case dmpconnectUserActionConstants.SET_PIN_CODE:
      return merge({}, state, { pinCode: action.pinCode });
    case dmpconnectUserActionConstants.EXPIRED_SESSION:
      return merge({}, state, { expiredSession: action.expiredSession });
    case dmpconnectUserActionConstants.DMPC_LOGOUT:
      return merge({}, state, { logoutPending: true });
    case dmpconnectUserActionConstants.DMPC_LOGOUT_SUCCESS:
      return merge({}, state, { loginReferer: state.loginReferer, loggedIn: false, pinCode: null });
    case dmpconnectApplicationActionConstants.DMPC_INIT_APPLICATION:
      return merge({}, state, { logoutPending: false, loginStepTwo: false });
    case dmpconnectUserActionConstants.DMPC_LOGIN_SUCCESS:
      return merge({}, state, { loggedIn: true, loginStepTwo: false, authenticationType: authenticationTypes.DIRECT, pinCode: null });
    case dmpconnectUserActionConstants.DMPC_ES_LOGIN_SUCCESS:
      return merge({}, state, { loggedIn: true, authenticationType: authenticationTypes.INDIRECT, pinCode: null });
    case dmpconnectUserActionConstants.DMPC_LOGIN:
      return merge({}, state, { expiredSession: false });
    case dmpconnectUserActionConstants.DMPC_ES_LOGIN:
      return merge({}, state, { esLoginfailedMessage: '', expiredSession: false });
    case dmpconnectUserActionConstants.DMPC_SET_ES_USER:
      return merge({}, state, { esUser: action.user });
    case dmpconnectUserActionConstants.DMPC_ES_LOGIN_FAILED:
      return merge({}, state, { esLoginfailedMessage: action.message, esUser: null });
    case dmpconnectUserActionConstants.DMPC_ES_LOGOUT:
      return merge({}, state, { requestESLogout: true, logoutPending: true });
    case dmpconnectUserActionConstants.DMPC_SET_LOGIN_REFERER:
      return merge({}, state, { loginReferer: action.loginReferer });
    case dmpconnectUserActionConstants.DMPC_CLEAR_LOGIN_REFERER:
      return merge({}, state, { loginReferer: null });
    case dmpconnectUserActionConstants.DMPC_SET_LOGIN_STEP_TWO:
      return merge({}, state, { loginStepTwo: action.status });
    case dmpconnectUserActionConstants.DMPC_SET_INS_PARAMS:
      return setInsParams(state, action.params);
    case dmpconnectUserActionConstants.DMPC_SET_INS_PARAM:
      return merge({},
        state,
        {
          insParams: merge({},
            state.insParams,
            { [action.key]: action.value }),
        });
    case dmpconnectUserActionConstants.DMPC_CHECK_ID_PARAMS:
      return setCheckIdParams(state, action.params);
    case dmpconnectUserActionConstants.DMPC_CHECK_ID_PARAM:
      return merge({},
        state,
        {
          identityParams: merge({},
            state.identityParams,
            { [action.key]: action.value }),
        });
    default:
      return state;
  }
}
